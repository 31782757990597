import React from "react"
import { FaFacebook, FaTwitterSquare, FaInstagram } from "react-icons/fa"

export default [
  {
    icon: <FaFacebook />,
    url: "",
  },
  {
    icon: <FaTwitterSquare />,
    url: "https://twitter.com/escambiasoft",
  },
  {
    icon: <FaInstagram />,
    url: "",
  },
]
