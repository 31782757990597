import React from "react"
import styles from "../css/footer.module.css"
import links from "../constants/links"
import socialIcons from "../constants/social-icons"
//import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Footer = () => {
return (
    <footer className={styles.footer}>
        <div className={styles.links}>
        {links.map((item, index) => {
            return (
            <AniLink paintDrip hex="#AEECEE" key={index} to={item.path}>
                {item.text}
            </AniLink>
            )
        })}
        </div>

        <div className={styles.copyright}>
        8950 SW 74 Court, Suite 2201, Miami, FL 33156
    </div>
    <div className={styles.copyright}>
        phone: 800-564-1370  Email: info@escambiasoft.com
    </div>
        <div className={styles.copyright}>
            copyright &copy; EscambiaSoft {new Date().getFullYear()} all rights reserved
        </div>
    </footer>
)
}

export default Footer;
