export default [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/projects",
    text: "sample projects",
  },
  {
    path: "/blog",
    text: "blog",
  },
  {
    path: "/contact",
    text: "contact",
  },
  {
    path: "/career",
    text: "jobs",
  }
]
